html, body, #root { height: 100%; width: 100%; font-family: 'Source Sans Pro', sans-serif; }
html { font-size: 72.5%; background: #f8f7f6; }
body { font-size: 15px; font-size: 1.5rem; font-weight: 500; }
* { box-sizing: border-box; outline: 0 none; }
h1,h2,h3,h4,h5 { margin: 0; padding: 0; }
a { text-decoration: none; }
input, select, textarea { font-family: 'Source Sans Pro', sans-serif; }
p { line-height: 150% }

.mini-spinner{display:inline-block;position:relative;width:20px;height:20px;transform:scale(0.3);position:absolute;margin: -7px 0 0 -30px;}.mini-spinner div{box-sizing:border-box;display:block;position:absolute;width:51px;height:51px;margin:6px;border:6px solid #fff;border-radius:50%;animation:mini-spinner 1.2s cubic-bezier(.5,0,.5,1) infinite;border-color:#fff transparent transparent transparent}.mini-spinner div:nth-child(1){animation-delay:-.45s}.mini-spinner div:nth-child(2){animation-delay:-.3s}.mini-spinner div:nth-child(3){animation-delay:-.15s}@keyframes mini-spinner{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}
.mini-spinner-ghost{display:inline-block;position:relative;width:20px;height:20px;transform:scale(0.3);position:absolute;margin: -7px 0 0 -30px;}.mini-spinner-ghost div{box-sizing:border-box;display:block;position:absolute;width:51px;height:51px;margin:6px;border:6px solid #ff3b5b;border-radius:50%;animation:mini-spinner-ghost 1.2s cubic-bezier(.5,0,.5,1) infinite;border-color:#ff3b5b transparent transparent transparent}.mini-spinner-ghost div:nth-child(1){animation-delay:-.45s}.mini-spinner-ghost div:nth-child(2){animation-delay:-.3s}.mini-spinner-ghost div:nth-child(3){animation-delay:-.15s}@keyframes mini-spinner-ghost{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}

.PhoneInputInput {
  border: 0;
  background: transparent;
}

.PhoneInput {
  display: flex !important;
}
